import { useClient } from '../../client/client';
import { PlatformNamesEnum } from '../../client/types';
import { UserSession } from '../utils/UserSession';

export const useLogin = () => {
  const client = useClient('UserLoginWithPassword');

  const login = async (payload: { cnpj: string; password: string }) => {
    const fragment = `
      token {
        token,
        iat
      }
    `;

    UserSession.clear();

    const context = await client.fetch({
      variables: {
        user: payload.cnpj,
        password: payload.password,
        platformName: PlatformNamesEnum.PwaPositivador
      },
      fragment
    });

    const token = context.result?.token?.token;

    if (token) {
      UserSession.setToken(token);
      return true;
    }

    return false;
  };

  return { status: client.loading ? 'loading' : 'pending', login };
};
